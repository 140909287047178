import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"

import {
  StyledSignUpHeaderLanderMindfulness,
  StyledSignUpLedeLanderMindfulness
} from "../styledComponents/signUpFormLanderMindfulnessCollection"

import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
//import addToMailchimp from 'gatsby-plugin-mailchimp'
import { analyticClickEvent } from "./googleAnalytics"


const SignupFreeEnergyHealingForm = ({ createCookie}) => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const [usrEmail, setUserEmail] = useState("")

  const interests = [
    // 07010ff6a3
    // curl -H "Authorization: apikey cedd0ed6f90a7c8205e334f8a9218411-us1" https://us1.api.mailchimp.com/3.0/lists/07010ff6a3/interest-categories/2e1178ad10/interests
    // 2e1178ad10 - Audio Purchase 
    {
        "id": "91e32ff4d0",
        "name": "Lander - Mindfulness Collection - Upsell Sleep / Anxiety"
    }
  ]

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.fullName) {
      errors.fullName = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      fullName: "",
      email: "",
    },
    validate,
    onSubmit: (
      {
        fullName,
        email,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      
      const bodyFormData = new FormData()
      bodyFormData.set("fullName", fullName)
      bodyFormData.set("email", email)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/8870/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)

          let fName, lName
          if(values.fullName.trim().indexOf(' ') !== -1){
            fName = fullName.split(' ').slice(0, -1).join(' ')
            lName = fullName.split(' ').slice(-1).join(' ')
          } else {
            fName = fullName
            lName = ''
          }
          axios.post("/.netlify/functions/formHandler" , {firstName:fName, emailAddress:email.toLowerCase(), interest: interests[0]})
          .then(data => {
            console.log('ERROR ',data)
          })
          .catch(console.log)

          setMessageSent(true)
          setIsSuccessMessage(true)
          setUserEmail(email)
          analyticClickEvent('sendform','mindfulness', '', '')
          // createCookie('newsletter')
          // navigate("/thank-you-signup")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      if(!isSuccessMessage){
        // setIsSuccessMessage(false)
      } 
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <>
    {isSuccessMessage ?
      <StyledSignUpHeaderLanderMindfulness thankyou>Please check your mailbox <strong>{usrEmail}</strong><br /> 
      You have been sent an e-mail from <strong>karina@karinagrant.co.uk</strong></StyledSignUpHeaderLanderMindfulness>
    : 
    <>
    <StyledSignUpHeaderLanderMindfulness>Sign up to my newsletter and receive your FREE Quantum-Touch eBook &amp; audio session from the founder Richard Gordon<br />As a bonus I will also send you a FREE login to my Essential Mindfulness Collection</StyledSignUpHeaderLanderMindfulness>

    <StyledForm onSubmit={handleSubmit}>
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="fullname">
          <StyledLabelHeader
            required
            isEmpty={errors.fullName && touched.fullName}
            hidden={true}
            // className="hidden"
          >
            Name
          </StyledLabelHeader>
          <StyledInput
            id="fullName"
            name="fullName"
            type="text"
            placeholder="First Name"
            onChange={handleChange}
            value={values.fullName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.fullName && touched.fullName ? (
          <span className="error-message">{errors.fullName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="email">
          <StyledLabelHeader
            required
            hidden={true}
            isEmpty={errors.email && touched.email}
          >
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="email"
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
            placeholder="Email Address"
            onBlur={handleBlur("email")}
            required
          />
        </StyledLabel>
        {errors.email && touched.email ? (
          <span className="error-message">{errors.email}</span>
        ) : null}
      </StyledFormElementWrapper>
      
      <StyledButton
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      >
      Yes! Send me my FREE Collection
      </StyledButton>
        
    </StyledFieldset>
    
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
    </>
    }
  </>
  )
}

export default SignupFreeEnergyHealingForm
