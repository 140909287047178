import React, { useEffect, useState } from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { SectionSingleProduct } from "../styledComponents/section"
import { StyledLanderPromoBox, StyledSignUpFreeSleepFormLanderMindfulness } from "../styledComponents/signUpFormLanderMindfulnessCollection"
import SignupFreeSleepForm from "../components/formFreeSleepSignUp"
import HeroBannerSleep from "../components/HeroBannerSleep"
import { HeroHeaderSleep, HeroTextSleep, HeroDescriptionSleep } from "../styledComponents/heroBannerSleep"
import { StyledLanderMindfulnessCollection } from "../styledComponents/lander/mindfulnessCollection"
import { StyledButton } from "../styledComponents/button"
import { analyticClickEvent } from "../components/googleAnalytics"
import SignupFreeEnergyHealingForm from "../components/formFreeEnergyHealingSignUp"

const EnergyHealingLander = ({ data, location }) => {
  const [isMindfulnessCollectionOpen, setisMindfulnessCollectionOpen] = useState(false);
  const [isKarinaMoreInfoOpen, setisKarinaMoreInfoOpen] = useState(false);
  const toggleMindfulnessCollection = (value) => {
    setisMindfulnessCollectionOpen(!isMindfulnessCollectionOpen)
  }

  const [countryCode, setCountryCode] = useState("") // store token
  useEffect(() => {
    fetch('/geolocation',{
    headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    })
    .then(function(response){
        return response.json();
    })
    .then(function(geoLocationData) {        
        setCountryCode(geoLocationData?.geo?.country?.code)
    })
}, [])
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <Layout location={location} mode="night" isLander landerType="sleepLander">
    <SEO 
        title="The Essential Mindfulness Collection" 
        description="This collection has a mindful meditation to suit each intention and bring your back to the present moment, at different times of the day."
        image="https://www.karinagrant.co.uk/images/mindfulness-collection.jpg"
        url="/mindfulness-collection-offer"
      />
    <HeroBannerSleep 
        isLander
        image="/images/backgrounds/background-mindfulness-hero-sm.png" 
        type="sleepLander"
      >
          <HeroTextSleep>
            <img 
              src="/images/mindfulness-collection-phone-hero.png"
              alt="example image of the free sleep audio" 
              layout="fixed"
            />
            <HeroHeaderSleep isLander="true">
            Do you want to know more about energy healing?
            </HeroHeaderSleep>
            <HeroDescriptionSleep>
            FREE Mindfulness Meditation Collection <span>From Karina Grant</span>
            </HeroDescriptionSleep>
            <HeroDescriptionSleep>
            This collection has a mindful meditation to suit <strong>each intention</strong> and <strong>bring your back to the present moment, at different times of the day</strong>. 
            </HeroDescriptionSleep>
          </HeroTextSleep>
      </HeroBannerSleep>

      <StyledSignUpFreeSleepFormLanderMindfulness type="sleepLander">
        <SignupFreeEnergyHealingForm />
      </StyledSignUpFreeSleepFormLanderMindfulness>
        
      <SectionSingleProduct>
        <StyledLanderMindfulnessCollection showAll={isMindfulnessCollectionOpen} showMoreKarina={isKarinaMoreInfoOpen}>
          <div className="product-description" itemProp='description'>
            <div className="intro-text-wrapper">
              <p className="intro">Karina has been teaching advanced healing and meditation for 15 years. She has purposefully created short meditations that you can listen to anywhere - anytime. </p>
              <p className="intro"><strong>Suitable for beginners and advanced meditators</strong>... simply listen and feel yourself melt into a state of stillness and peace.</p>
              <p className="intro">Thousands of scientific studies have <strong>proven that mindfulness increases physical and emotional health</strong>. Even 1 mindful minute can entirely shift the rest of your day. </p>
              <p className="intro">Imagine how your day will start after your quick 8 minute morning meditation? </p>
              <p className="intro">Karina's clients often ask her how she has so much life force energy. She always gives one answer - regular mindfulness &amp; meditation.</p>

              <figure className="karina-photo-wrapper">
                <StaticImage
                  src="../images/profile/karina-grant.jpg"
                  alt="Headshot of Karina Grant"
                  className="karina-headshot"
                />
                <figcaption><span>Karina Grant</span></figcaption>
              </figure>
              <h3>Karina Grant - Quantum-Touch energy healing Advanced Instructor and meditation teacher</h3>
              <p>Karina attended her first Louise Hay style breath work seminar at age 12.</p>
              <p>When her Father was diagnosed with Parkinson’s in 2005, she searched for ways to help his symptoms and discovered Quantum-Touch (QT). </p><p>After seeing the immediate, positive effect this had, she embarked on a fast track journey into exploring the most powerful energy healing and meditation methods, in order to help others.  </p>
              <p>In 2007 Karina was invited to become England’s advanced instructor for Quantum-Touch, and has instructed many meditation and healing training programs internationally.</p>
              <p className="last">Karina is able to perceive energy clearly, which is incredibly useful when helping others.</p>

              {!isKarinaMoreInfoOpen &&
                <StyledButton
                  onClick={() => {
                    setisKarinaMoreInfoOpen(true)
                    analyticClickEvent("More info button","CTA Button","Mindfulness Lander more info Karina", "")
                    }
                  }
                  >Read more
                </StyledButton>
              }

              {isKarinaMoreInfoOpen && 
              <>
                <p>As a natural progression in her fulfilling journey a practitioner and instructor, Karina started the HI CHI Integrated Health Institute in 2015. The HI CHI Integrated Health Institute offers powerful resources to facilitate balance and wellness.</p>
                <p>Karina thoroughly enjoys connecting with people from all walks of life and all belief systems. She spends her time teaching workshops and facilitating energy healing for others in Central London and via skype. Karina is able to perceive energy clearly, which is incredibly useful when helping others.</p>
                <p>Karina has spent over a decade teaching mindfulness, wellness and energy healing to both medical and complementary health care professionals. Her workshops are attended by Doctors, nurses, public health advisors, research scientists, CEO’s, parents, carers, charities, teachers and a variety of other professional backgrounds. She has also enjoyed teaching meditation in large corporations and has trained people from The World Health Organisation and other prestigious organisations.</p>
                <p>Karina has been invited to teach Wellness and Mindfulness sessions at global corporations and also at The British Government’s Department of Energy and Climate Change.</p>
                <p>She is hugely passionate about teaching in charities and non profit organisations. This includes teaching Mindfulness to amazing charities such as Groundswell, who do groundbreaking work with homeless people in London, how to achieve greater health and vitality.</p>
                <p>Karina is also delighted to have launched Permissioning and Transformation workshops in Europe. Due to the success of this program with nurses, it now counts towards CPD points for nurses and midwives. This set of techniques has been used highly effectively for insomnia and anxiety and Karina taught these techniques to many keyworkers during the first lockdown.</p>
                <p>Additionally, to support people during the covid isolation crisis Karina launched the HI CHI mindfulness phoneline to give people without smartphones access to the HI CHI App meditations for free. This is the first mindfulness phoneline to be launched in the UK and is being promoted by various charities.</p>
                <p>Karina is also a Bio-Energy Healing Practitioner, Reiki Practitioner, is fluent in many energy healing techniques and graduated from Tony Robbins Mastery University in 2001.</p>
                <p>It is Karina’s utmost belief that anything is possible, and that even the most severe of conditions can be overcome and at the very least managed well.</p>
                <p>Energy healing is in her opinion the fastest and most powerful way to facilitate recovery and wellness.</p>
                <p>Karina is a member of The British Holistic Medical Association and The College of Medicine.</p>
              </>
              }

              <h1 className="intro-mindfulness-collection">Introducing "The Essential Mindfulness Collection"</h1>
              <img src="/images/mindfulness-collection-phone-hero.png" className="intro-mindfulness-collection-promo" alt="The Essential Mindfulness Meditation Collection"/>
              <p className="intro">A Free Mindfulness Meditation Collection with <strong>13 guided mindful meditations</strong>!</p>
              <p className="intro">This collection has <strong>a mindful meditation to suit each intention</strong> and <strong>bring your back to the present moment, at different times of the day</strong>. </p>
              <p className="intro">Simply listen to something that will <strong>relax and reset you so easily again and again</strong>.</p>

              <h1>What do you get in the collection?</h1>
              <ul>
                <li>
                  <span className="headerInfo">
                    <h2><span>Lunchtime reset</span></h2>
                    <StaticImage 
                      src="../images/the-essential-mindfulness-collection/lunchtime-reset.jpg"
                      alt="7 min Lunctime reset - Mindfulness Meditation"
                      />
                  </span>
                  <span className="duration">7 min</span>
                    <p>Create brand new energy in your afternoon by listening to this quick 7-minute meditation. Lunchtime reset is very popular and will refocus you for a brand new perspective in the afternoon.</p>
                  </li>
                  <li>
                  <span className="headerInfo"><h2><span>Easy sleep</span></h2>
                  <StaticImage 
                    src="../images/the-essential-mindfulness-collection/mindfull-sleep.jpg"
                    alt="19 min Easy Sleep - Mindfulness Meditation"
                    />
                  </span>
                  <span className="duration">19 min</span>
                  <p>This 19-minute meditation will assist you in drifting off into a calm state of sleep.</p>
                </li>
                  <li>
                  <span className="headerInfo"><h2><span>Finding your feet</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/finding-your-feet.jpg"
                alt="19 min Easy Sleep - Mindfulness Meditation"
                /></span>
                <span className="duration">13 min</span>
                <p>This 13-minute meditation will guide your awareness to your feet which is very grounding. A way of reducing too much activity in the mind is to cultivate mindful awareness of our feet. This slows our thoughts down and brings us back into the body.</p><p>This meditation will help to relax your central nervous system as your mind and body are inspired to return to a state of calm.</p>
                </li>
                
                  <li>
                    <span className="headerInfo"><h2><span>Morning daily intention</span></h2>
                    <StaticImage 
                      src="../images/the-essential-mindfulness-collection/morning-daily-intention.jpg"
                      alt="8 min Morning - Mindfulness Meditation"
                      /></span>
                      <span className="duration">8 min</span>
                    <p>Start your morning with a personal daily intention that will set you up for a mindful day. When we begin the day with a present state of mind the rest of the day flows with much more ease and grace.</p>

                    <p>This quick 8-minute meditation will focus you on a positive and productive morning, filled with love for those around you.</p>
                    </li>
                </ul>
                {!isMindfulnessCollectionOpen &&
                  
                    <StyledButton
                      onClick={() => {
                        toggleMindfulnessCollection(true)
                        analyticClickEvent("More info button","CTA Button","Mindfulness Lander more info Collection", "")
                      }}>Read about the other audios in the collection
                    </StyledButton>
                }

                {isMindfulnessCollectionOpen && 
                <ul>
                  <li>
                    <span className="headerInfo"><h2><span>From anxiety to inner peace</span></h2>
                    <StaticImage 
                      src="../images/the-essential-mindfulness-collection/peace-inner.jpg"
                      alt="16 min inner peace - Mindfulness Meditation"
                      />      
                    </span>
                    <span className="duration">26 min</span>
                    <p>This 26-minute meditation has been created to gently guide you from anxiety to a state of inner peace. Simply listen and follow along with the guided visuals and mindful intentions, to calm and still your mind.</p>  
                  </li>
                <li>
                  <span className="headerInfo">
                    <h2><span>Mindful breathing</span></h2>
                    <StaticImage 
                    src="../images/the-essential-mindfulness-collection/awareness-of-the-breath.jpg"
                    alt="12 min Mindful breathing - Mindfulness Meditation"
                    /></span>
                    <span className="duration">12 min</span>
                    <p>This meditation will easily guide you into a state of mindful breathing. With each mindful breath, your mind will relax in equal measure. Allow and open your breath as you release your mind and return to the present moment.</p>
                </li>
              <li>
                  <span className="headerInfo"><h2><span>Cultivating mindfulness</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/cultivating-mindfulness.jpg"
                alt="15 min cultivating mindfulness meditation"
                />
                </span>
                <span className="duration">15 min</span>
                <p>This 15-minute meditation will help you to enhance your mindful awareness and connect you more easily to the present moment. Each time you listen you will increase your ability to focus your attention more mindfully.</p>
              </li>
              <li>
                  <span className="headerInfo"><h2><span>Compassion</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/compassion.jpg"
                alt="11 min compassion - Mindfulness Meditation"
                /></span>
                <span className="duration">11 min</span>
                
              <p>Listening to this 11-minute meditation will open your heart as you open into a state of mindful compassion. You will also enjoy feeling compassion from those around you whilst in a meditative state. This feels very supportive and calming.</p>
              </li>
              <li>
                  <span className="headerInfo"><h2><span>Being in my body</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/being-in-my-body.jpg"
                alt="21 min being in my body - Mindfulness Meditation"
                />
                </span>
                <span className="duration">21 min</span>
                <p>Enjoy listening to 21 minutes of a guided meditation that will mindfully connect you to an awareness of your body. The intention of this meditation is to help you feel more energised and focused, in a state of calm.</p>
                </li>
              <li>
                  <span className="headerInfo"><h2><span>Gratitude</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/gratitude.jpg"
                alt="15 min gratitude - Mindfulness Meditation"
                />
                </span>
                <span className="duration">15 min</span>
                <p>This 15-minute gratitude meditation will transform the way you feel as your mind actively looks for the blessings around you. Being in a regular state of mindful gratitude has been scientifically proven to increase happiness and enhance the immune system.</p>
                </li>
                <li>
                <span className="headerInfo"><h2><span>Mindful body scan</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/mindful-body-scan.jpg"
                alt="16 min Mindful body scan Meditation"
                />
                </span>
                <span className="duration">16 min</span>
                <p>This 16-minute meditation is one of the most taught and well known guided processes in mindful teachings. This meditation focuses on moving our attention mindfully throughout each part of the body without any judgment. </p><p>This trains the mind to respond much more effectively to stress and develops resilience. Although the aim isn't necessarily to relax it often promotes a sense of deep relaxation for people and is very popular.</p>
                </li>
              <li>
                  <span className="headerInfo"><h2><span>Activating your senses</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/activating-your-senses.jpg"
                alt="16 min activating your senses - Mindfulness Meditation"
                /></span>
                <p>This 16-minute meditation will guide your attention towards each of your senses. Activating the senses is a very effective way to experience and notice the world around us more. </p><p>This will easily bring your awareness out of your thoughts and into a calm, perceptive state. When we are aware of external sounds, sights, smells, tastes, and touch, our mind naturally calms and rebalances itself.</p>
                <span className="duration">16 min</span>
                </li>
                <li>
                  <span className="headerInfo"><h2><span>Compassion for me and you</span></h2>
              <StaticImage 
                src="../images/the-essential-mindfulness-collection/compassion-for-me-and-for-you.jpg"
                alt="25 min comapassion - Mindfulness Meditation"
                /></span>
                <span className="duration">25 min</span>
                <p>Listening to this 25 minute meditation opens your feelings of compassion for yourself and others. Science has proven that compassion boosts our immune system and strengthens the nervous system.</p>
                </li>
                </ul>
                }
              </div>
          </div>
        </StyledLanderMindfulnessCollection>
      </SectionSingleProduct>
      
      <StyledLanderPromoBox>
        <p>Normally, this would be {countryCode === 'US' ? '$18.99' : '£13.99'}. But right now, this meditation is my gift to you, <span>absolutely FREE!</span></p>
      </StyledLanderPromoBox>
      <StyledSignUpFreeSleepFormLanderMindfulness type="sleepLander" position="bottom">
        <SignupFreeSleepForm />
      </StyledSignUpFreeSleepFormLanderMindfulness>
    </Layout>
    </>
  )
}

export default EnergyHealingLander